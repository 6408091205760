import { render, staticRenderFns } from "./Normal.vue?vue&type=template&id=6220be16&scoped=true&lang=pug&"
import script from "./Normal.vue?vue&type=script&lang=js&"
export * from "./Normal.vue?vue&type=script&lang=js&"
import style0 from "./Normal.scss?vue&type=style&index=0&id=6220be16&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6220be16",
  null
  
)

export default component.exports