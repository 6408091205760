<template lang="pug">
  .root
    component-header-index

    div 左欄はファンクラブの開設状況により異なります

    div 通常配信・イベント配信
    div 配信開始予定日時
    
    input(type="date" v-model="ui.date")
    select(v-model="ui.hour")
      option(v-for="option in ui.options.hour" :value="option") {{ option }}
      
    select(v-model="ui.minute")
      option(v-for="option in ui.options.minute" :value="option") {{ option }}
    
    div {{ `文章[${timeFullAddOneHourStr}]` }}
    
    div ※イベント参加中は配信スケジュール作成の有無にかかわらず 1 日 2 回以上配信をすることはできません

    button(@click="onSubmit()") 登録する
    button(@click="onCancel()") キャンセル

    component-footer-index
</template>

<style src="./Normal.scss" lang="scss" scoped>
</style>

<script>
import moment from 'moment';
import _ from 'lodash';

export default {
  name: "SettingRoomNameEvent",
  components: {},
  data() {
    return {
      ui: {
        options: {
          hour: _.range(0, 24).map(v => _.padStart(`${v}`, 2, '0')),
          minute: _.range(0, 60).map(v => _.padStart(`${v}`, 2, '0')),
        },
        date: undefined,
        hour: undefined,
        minute: undefined,
      },
      form: {
        content: undefined,
      }
    }
  },
  props: {
    propsTitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    timeFull: function () {
      if (!this.ui.date || !this.ui.hour || !this.ui.minute) {
        return undefined;
      }
      return moment(this.ui.date).add(this.ui.hour, 'hour').add(this.ui.minute, 'minute')
    },
    timeFullAddOneHourStr: function () {
      if (!this.timeFull) {
        return ''
      }
      const t = this.timeFull.add(1, 'hour');
      return `${t.year()}年${t.month() + 1}月${t.date()}日${_.padStart(t.hour(), 2, '0')}時${_.padStart(t.minute(), 2, '0')}分`;
    }
  },
  watch: {},
  mounted() {},
  async created() {},
  updated() {},
  destroyed() {},
  methods: {
    async onSubmit() {
      this.$router.push({ name: 'TopIndex' })
    },
    onCancel() {
      this.$router.push({ name: 'TopIndex' })
    }
  }
};
</script>
